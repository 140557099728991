import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import PodcastQuestions from '../../components/Tools/PodcastQuestions'
import questions from '../../components/Tools/PodcastQuestions/questions'
import FAQ from '../../components/FAQ'
import {Link} from "gatsby";

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="Podcast Interview Question List for 2021"
          description="The ultimate list of podcast interview questions with a generator. Make sure you are asking your guest great questions."
        />

        <article>
          <p>
            <img src="/images/podcast-questions-list.jpg" alt="Podcast Questions List" />
          </p>
          <h1>Podcast Interview Question List for 2021</h1>
          <p>
            Preparation and research is the key to an inspiring interview. Asking questions about the guest’s expertise
            and their background will often provide the best content for your listeners. View our guide on conducting a
            great podcast interview.
          </p>
          <h2>Podcast Question Generator</h2>
          <p>
            Use our generator to find some great podcast interview questions. Help inspire some ice breaker questions to
            ask your guest. We recommend you combine these topics with your own question to create the perfect unique
            episode!
          </p>
          <PodcastQuestions />
          <h2>Should I send interview questions upfront?</h2>
          <p>
            Some guests require a pre-vetted list of questions. It’s polite to send the questions ahead of time. We
            recommend you don’t send the exact questions, but instead the topic area that you want to cover.
          </p>
          <p>Sending the topics in advance can help ease nerves and allows the guest to todo some preparation.</p>
          <h2>Questions to avoid asking</h2>
          <p>
            Just as important as knowing what questions to ask, is knowing the questions to skirt. If the guest is
            regularly interviewed you do your research to avoid asking common questions. You risk getting a canned
            answer that does not resonate with your audience.
          </p>
          <p>
            As a matter of curiosity, you should avoid asking any deeply personal right away. You can start with some
            ice breaker questions, such as “What does your morning routine look like?”
          </p>
          <p>
            Don’t ask too many questions! You should be listing most of the time, thinking of a natural flow to segway
            into your next question. Check out our <Link to='/how-to-interview-for-a-podcast/'>guide to interviewing</Link>.
          </p>

          <h2>Question Tips</h2>

          <p>Six tips for asking great interview question. Get interesting story's from your guest.</p>

          <ol>
            <li>Ask unique questions (or you may end up with canned responses)</li>
            <li>Avoid Negatively phrased subjects</li>
            <li>Double-check common myths and research in advance</li>
            <li>Fine Deep questions</li>
            <li>Ask great followup questions</li>
            <li>Have a mix of thought provoking and open ended questions</li>
          </ol>
          <h2>Great podcast questions</h2>
          <p>Below is our full podcast question list from our generator.</p>
          <ul>
            {questions.map(question => (
              <li>{question}</li>
            ))}
          </ul>

          <br/>
          <h2>Frequently Asked Questions</h2>
          <FAQ items={[
            {
              'question': 'What are the best podcast topics?',
              'answer': 'Find a topic you, your audience and your guest can resonate with. You are best to focus on a niche, For example rather than food, you could concentrate on baking.'
            },
            {
              'question': 'What is the ideal length for a podcast interview?',
              'answer': 'Agree on an end time upfront with your guests and stick with it, If you don’t know how long you need, then produce a topic list and assign 10 minutes per topic. 40 minutes works as a strong starting point. '
            },
            {
              'question': 'Are podcast guests typically paid for their appearance?\n',
              'answer': 'No. There are cases where you can pay a podcast guest. Generally, if they are an expert on a special episode. The majority of podcast guests are unpaid.'
            },
            {
              'question': 'How to get interviewed on podcasts?',
              'answer': 'Start reaching out to smaller podcast/podcast hosts that you share interested with. Once you start to build up a portfolio you can then share this to get on larger POdcasts. You should utilise any network or contacts yo have and reach out to them, by posting on social media you have started doing guest appearances on Podcasts!'
            },
          ]} />

        </article>
      </Layout>
    )
  }
}

export default Page
