import React from 'react'
import styles from './index.module.scss'
import { Link } from 'gatsby'
import questions from './questions';

class PodcastQuestions extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      questionIndex: Math.floor(Math.random() * Math.floor(questions.length)),
    }
  }

  newQuestion = () => {

    this.setState({
      questionIndex: Math.floor(Math.random() * Math.floor(questions.length)),
    })
  }

  render() {

    const {questionIndex} = this.state;

    return (
      <div className={styles.podcastQuestion}>

        <div>{questions[questionIndex]}</div>

        <button onClick={this.newQuestion} >New Question</button>

      </div>
    )
  }
}

export default PodcastQuestions
